<script>
	export let videourl = '';
</script>

<style>
	iframe { display: block; margin: auto; max-width:100%; max-height: 300px; }
</style>

<div class="videocontent">
	<iframe width="560" height="300" src="{videourl}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
</div>