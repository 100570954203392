<svelte:head>
  <title>Gebaren opzoeken</title>
</svelte:head>

<script>
	import Videoplayer from './Videoplayer.svelte';
	import Youtubeplayer from './Youtubeplayer.svelte';
	import Facebookplayer from './Facebookplayer.svelte';
	import InputWithButton from './InputWithButton.svelte';
		
	let searchterm = '';
	let gesture = '';
	let videoUrl = '';
	
	function search(event) {
		gesture = event.detail.value;
		history.pushState({'search': gesture}, 'Gebaren: ' + gesture, '?search=' + gesture);
		videoUrl = getVideourlAsync();
	}
	
	async function getVideourlAsync() {
		const res = await fetch('http://franklippes.nl/gebaren/search.php?gesture=' + gesture);
		const videoUrl = await res.text();
		if (res.ok) {
			if(!videoUrl)
				return '404';
			else
				return videoUrl;
		} else {
			throw new Error(videoUrl);
		}
	}
	
	const urlParams = new URLSearchParams(window.location.search);
	const searchStart = urlParams.get('search');
	if(searchStart){
		gesture = searchStart;
		videoUrl = getVideourlAsync();
	}
	
</script>

<style>
	#app { margin-bottom: 25px; }
	h1, p {text-align: center;}
	:global(.input-with-button p) {text-align: center;}
</style>

<div id="app">
	<h1>Gebarenzoeker</h1>
	<InputWithButton buttonText="Zoeken" on:submit={search} />
	
	{#await videoUrl}
		<p>searching...</p>
	{:then videoUrl}
		{#if videoUrl === '404'}
			<p>Er is geen gebaar gevonden voor {gesture}</p>
		{:else if videoUrl.includes('/embed/')}
			<Youtubeplayer videourl={videoUrl}></Youtubeplayer>
		{:else if videoUrl.includes('facebook')}
			<Facebookplayer videourl={videoUrl}></Facebookplayer>
		{:else if videoUrl}
			<Videoplayer videourl={videoUrl}></Videoplayer>
		{/if}
		{#if videoUrl !== '404'}
			<p><strong>{gesture}</strong></p>			
		{/if}
	{:catch error}
		<p>Er is iets misgegaan...</p>
	{/await}
	<hr>
	<p>
		Alle informatie komt van <a href="https://www.lerengebaren.nl/" target="_blank">https://www.lerengebaren.nl/</a>
		en van het YouTube kanaal <a href="https://www.youtube.com/channel/UCka3vwMWT5corqam_M0sXDQ" target="_blank">Gebaar van de Dag</a>.
		<br>Deze pagina is  alleen gemaakt om het op mobiel makkelijker te maken om gebaren op te zoeken.
	</p>
</div>