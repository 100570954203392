<script>
	import { createEventDispatcher } from 'svelte';
	const dispatch = createEventDispatcher();
	
	export let placeholderText = '';
	export let inputValue = '';
	export let buttonText = 'Submit';
	
	function checkEnter(event){
		if (event.key === 'Enter') {
			submit();
		}
	}
	
	function submit(){
		dispatch('submit', {
			value: inputValue
		});
	}
	
	function select(node){
		console.log('morroe');
		node.focus();
		node.select();
	}
	
</script>

<style>
	button { cursor: pointer; }
</style>

<div class="input-with-button">
	<p>
		<input type="text" placeholder="{placeholderText}" bind:value={inputValue} on:keydown={checkEnter} in:select />
		<button on:click={submit}>{buttonText}</button>
	</p>
</div>