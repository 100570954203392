<script>
	export let videourl = '';
	
	let paused = true;
	let ended = true;
		
	function togglePlay(e){
		if (!paused) myvideo.pause();
		else myvideo.play();
	}
</script>

<style>
	video { display: block; margin: auto; max-width:100%; max-height: 300px; }
</style>

<div class="videocontent">
	<video id="myvideo" onclick="togglePlay()" controls="controls" bind:paused>
		<source src={videourl} type='video/mp4' />
		<track kind="captions">
	</video>
</div>