<script>
	export let videourl = '';
</script>

<style>
	iframe { display: block; margin: auto; max-width:100%; max-height: 300px; }
</style>

<div class="videocontent">
	<iframe src="{videourl}" width="560" height="300" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture;"></iframe>
</div>